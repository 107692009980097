<template>
  <div class="userIndex-box inform-page">
    <div class="content">
      <div class="lever-one"></div>
      <div class="lever-two search-box">
        <div class="sub-title">站内信</div>
        <user bgColor="#fff" class="pl30"></user>
        <div class="user-search flex" v-if="0">
          <div class="searchIcon"><img src="@/assets/img/search.png" /></div>
          <input
            type="text"
            placeholder="请输入站内信名称搜索"
            class="searchInput"
          />
        </div>
      </div>
      <div class="inform-title">
        <div class="status-box cursor">
          <div class="status">
            <div
              class="item"
              :class="status === 'didnt' ? 'cur' : ''"
              @click="statusHandle('didnt')"
            >
              <i class="icon none"></i>
              <span>未读</span>
            </div>
            <div
              class="item"
              :class="status === 'done' ? 'cur' : ''"
              @click="statusHandle('done')"
            >
              <i class="icon had"></i>
              <span>已读</span>
            </div>
          </div>
          <div class="timer-choose">
            <el-date-picker
              v-model="value1"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dateChangeHandle"
            >
            </el-date-picker>
            <div class="start ">
              <span>{{ searchObj.startDate || '起始时间' }}</span>
            </div>
            <i class="small-line">-</i>
            <div class="start ">
              <span>{{ searchObj.endDate || '结束时间' }}</span>
            </div>
          </div>
          <div class="search-inp">
            <input
              type="text"
              placeholder="关键词搜索"
              v-model="searchObj.keyword"
              @keyup.enter="getList(1)"
            />
            <i class="icon" @click="getList(1)"></i>
          </div>
        </div>
        <div class="delete-box fcenter cursor" @click="deleteHandle">
          <i class="icon"></i>
        </div>
      </div>
      <ul class="inform-list" ref="container">
        <!-- li 加cur是整个被选择， -->
        <li
          :class="item.status ? 'cur' : ''"
          v-for="(item, index) in comList"
          :key="index"
        >
          <div class="des-text">
            <div
              class="circle fcenter cursor"
              @click="chooseDeleteHandle(item.status, index)"
            >
              <i class="circle-i"></i>
            </div>
            <!--status 加上 cur 已读状态 -->
            <div
              class="status fcenter cursor"
              :class="item.isRead ? 'cur' : ''"
              @click="goDetail(item.id)"
            >
              <i class="icon"></i>
            </div>
            <div class="class-name cursor" @click="goDetail(item.id)">
              <span>{{ item.title }}</span>
              <!-- <p v-html="item.content"></p> -->
            </div>
          </div>
          <div class="timer">
            {{ item.time }}
          </div>
        </li>
      </ul>
      <CommonEmpty
        class="empty-inform-list"
        v-if="comList.length == 0 && searchObj.page > 1"
      ></CommonEmpty>
      <Loading :loaded="loadend" :loading="loading"></Loading>
    </div>
    <el-dialog :show-close="false" :visible.sync="centerDialogVisible" center>
      <div class="close-box">
        <div class="close-icon" @click="centerDialogVisible = false"></div>
        <img src="@/assets/img/user/delete-img.png" alt="" />
        <div class="sure-btn" @click="deleteMesHandle"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import User from '@/components/user.vue'
import { systemInfoListApi, systemInfoDeleteApi } from '@/api/user'
import CommonEmpty from '@/components/ComEmpty'
import Loading from '@/components/Loading.vue'
import { Scroll } from '@/utils/loading'
import { setItem, getItem } from '@/assets/js/storage.js'
export default {
  name: 'course',
  components: {
    User,
    Loading,
    CommonEmpty
  },
  data() {
    return {
      value1: '',
      centerDialogVisible: false,
      status: 'didnt', // didnt 未读，done 已读
      itemChooseIds: [],
      // 列表
      comList: [],
      loading: false,
      loadend: false,
      allTotal: '',

      // 搜索条件
      searchObj: {
        page: 1,
        pagesize: 10,
        keyword: '',
        status: 0, // 0 未读，1 已读
        startDate: '',
        endDate: ''
      }
    }
  },
  mounted() {
    // 详情过来的
    let hasVal = this.$route.query.searchVal
    let isDetail = this.$route.query.isDetail
    if (isDetail) {
      this.searchObj.keyword = hasVal
      this.searchObj.status = ''
      this.status = 3
    }
    this.getList()
    // 下拉加载 this.allTotal > this.comList.length
    this.$scroll(this.$refs.container, () => {
      if (this.allTotal > this.comList.length) {
        !this.loading && this.getList()
      }
    })
    Scroll.listenTouchDirection()
  },
  watch: {
    noReadLength() {
      if (this.noReadLength === 0) {
        let userInfo = getItem('user')
        if (!userInfo) {
          userInfo = {}
        }
        userInfo.unreadMsgQty = 0
        setItem('user', userInfo)
        this.$store.dispatch('setUserModal')
      }
    }
  },
  methods: {
    // 列表加载
    getList(topVal) {
      if (!topVal) {
        if (this.loading) return
        if (this.loadend) return
      } else {
        this.searchObj.page = 1
      }
      this.loading = true
      systemInfoListApi(this.searchObj).then(res => {
        if (res.code === 0) {
          // 添加标识
          res.data.list.forEach(item => {
            item.status = false
          })
          this.allTotal = res.data.count
          if (topVal) {
            this.comList = res.data.list
          } else {
            this.comList.push.apply(this.comList, res.data.list)
          }
          this.loading = false
          this.loadend = res.data.list.length < this.searchObj.pagesize
          this.searchObj.page = this.searchObj.page + 1
        }
      })
    },
    // 点击改变选中状态
    chooseDeleteHandle(status, index) {
      this.comList[index].status = !status
    },
    // 弹出过滤
    deleteHandle() {
      let hasChoose = this.comList.some(item => item.status)
      if (!hasChoose) {
        this.$message.error('请选择要删除的信息')
        return
      }
      this.centerDialogVisible = true
      this.itemChooseIds = this.comList
        .filter(item => item.status)
        .map(item => item.id)
    },
    // 确认删除
    deleteMesHandle() {
      systemInfoDeleteApi({
        ids: this.itemChooseIds.toString()
      }).then(res => {
        if (res.code === 0) {
          // 把 id 一个一个删掉
          this.comList.forEach((item, index) => {
            this.itemChooseIds.forEach(idB => {
              if (item.id == idB) {
                this.comList.splice(index, 1)
              }
            })
          })
          this.$message({
            type: 'success',
            message: `删除成功!`
          })
          this.centerDialogVisible = false
          this.itemChooseIds = []
        }
      })
    },
    // 日期改变触发
    dateChangeHandle(val) {
      if (val) {
        this.searchObj.startDate = val[0]
        this.searchObj.endDate = val[1]
        this.getList(1)
      }
    },
    // 状态触发
    statusHandle(status) {
      this.status = status
      this.searchObj.status = status === 'didnt' ? 0 : 1
      this.getList(1)
    },
    // 详情跳转
    goDetail(id) {
      this.$router.push({
        name: 'userDetail',
        query: {
          id
        }
      })
    }
  },
  computed: {
    noReadLength() {
      if (this.searchObj.status === 0) {
        return this.comList.length
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-range-editor.el-input__inner {
  position: absolute;
  opacity: 0;
  z-index: 9;
}
/deep/.el-dialog,
/deep/.el-pager li {
  box-shadow: none;
  background: none;
}
.close-box {
  position: relative;
  width: 508px;
  height: 446px;
  left: 100px;
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .sure-btn {
    position: absolute;
    bottom: 114px;
    left: 226px;
    width: 210px;
    height: 64px;
    border-radius: 50px;
    cursor: pointer;
  }
}
</style>
