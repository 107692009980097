<template>
  <div>
    <div class="emptyData">
      <img :src="realImg" />
      <p>
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    realImg: {
      type: String,
      default() {
        return require('@/assets/img/courseMsg.jpg')
      }
    },
    title: {
      type: String,
      default() {
        return '抱歉，没有内容'
      }
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
