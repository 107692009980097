<template>
  <div class="Loads" v-if="loading || !loaded" style="margin-top: 50px;">
    <template v-if="loading">
      <div class="loading"></div>
      正在加载中
    </template>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loaded: Boolean,
    loading: Boolean
  }
}
</script>
<style lang="scss" scoped>
.Loads {
  margin: 0 auto;
  text-align: center;
  .loading {
    margin: 40px 0;
  }
}
</style>
