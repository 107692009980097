<template>
  <div class="menent flex row-between pl15">
    <div class="flex">
      <div class="stion cup">
        <img src="@/assets/img/img1.png" @click="$router.push({ name: 'user' })" />
      </div>
      <div class="stion cup">
        <img src="@/assets/img/img2.png" @click="$router.push({ name: 'userInform' })" /><i v-if="user && user.unreadMsgQty"></i>
      </div>
    </div>
    <div class="userBox tapHover cup mr15">
      <div class="user flex row-between" :style="'background-color:' + bgColor">
        <div class="flex" v-if="user">
          <div class="log ml15">
            <img :src="user.avatar ? user.avatar : imgSrc" />
          </div>
          <div class="name pl10 f12 c333 hide text-center">
            {{ user.nickName ? user.nickName : user.name }}
          </div>
        </div>
        <div class="flex" v-else @click="$router.push('/login')">
          <div class="log ml25"><img src="../assets/img/user-log.png" /></div>
          <div class="name pl15 f12 c333">请登录</div>
        </div>
        <div class="icon" v-if="user"></div>
      </div>
      <div class="full" v-if="user">
        <div class="hsted f16 c666 flex" @click="toActivity">
          <img src="@/assets/img/img27.png" />已报名活动<span
            v-if="$store.state.stateNum"
            >{{ $store.state.stateNum }}</span
          >
        </div>
        <div class="hsted f16 c666 flex" @click="toForget">
          <img src="@/assets/img/img5.png" />修改密码
        </div>
        <div class="hsted f16 c666 flex" @click="out">
          <img src="@/assets/img/img6.png" />退出
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getItem, setItem } from '../assets/js/storage.js'
import { mapState } from 'vuex';
export default {
  name: 'user',
  props: ['bgColor'],
  data() {
    return {
      imgSrc: require('../assets/img/user-log.png'),
      show: false,
      userShow: false
    }
  },
  mounted() {
    this.$store.dispatch('setUserModal')
    // 有缓存代表已经登录过
    if (this.user) {
      //初始化当前用户报名了多少个活动
      this.$store.dispatch('setNumModal')
      //获取用户数据
      this.getUser()
    }
  },
  computed: {
    ...mapState({
      user: state => state.stateUser
    })
  },
  methods: {
    useOver(){
      this.userShow = true;
      var that = this;
      setTimeout(() => {
        that.show = true;
      }, 200)
      
    },
    useOut(){
      this.show = false;
      this.userShow = false;
    },
    async getUser() {
      var res = await this.$api.get('/api/member_center/index')
      if (res.code == 0) {
        var userInfo = res.data
        setItem('user', userInfo)
        this.$store.dispatch('setUserModal')
      } else {
        // 顺便判断一下是否登录过期
        this.user = ''
        localStorage.removeItem('user')
      }
    },
    toForget() {
      this.$bus.$emit('changePassM', 'password')
    },
    async out() {
      var res = await this.$api.get('/api/login/logout');
      localStorage.removeItem('user')
      this.$store.dispatch('setUserModal')
      this.$router.push('/login')
    },
    toActivity() {
      this.$router.push('/home/activity')
    }
  }
}
</script>

<style lang="scss" scoped>
.menent {
  width: 100%;
  max-width: 320px;
  .userBox {
    position: relative;
    transition: all 0.5s;
    &.tapHover {
      border-radius: 10px;
      &:hover {
        box-shadow: 0 0 10px 0 rgba(102, 102, 102, 0.2);
        .full {
          top: 50px;
          opacity: 1;
          height: auto;
          box-shadow: 0 0 10px 0 rgba(102, 102, 102, 0.2);
        }
        .user {
          .icon {
            background-image: url(../assets/img/img3.png);
          }
        }
      }
    }
    .full {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 199;
      background-color: #ffffff;
      width: 160px;
      height: 0;
      transition: all 0.5s;
      opacity: 0;
      overflow: hidden;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .hsted {
        padding-left: 15px;
        height: 55px;
        span {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          background-color: red;
          margin-left: 10px;
          text-align: center;
        }
        img {
          margin-right: 6px;
          vertical-align: middle;
        }
      }
    }
    .user {
      position: relative;
      z-index: 200;
      width: 100%;
      min-width: 160px;
      height: 50px;
      background-color: #ffffff;
      border-radius: 10px;
      .log{
        width: 28px;
        height: 28px;
        border-radius: 50%;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .name{
        &.hide{
          width: 85px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow:ellipsis;
          height: 14px;
        }
      }
      .icon {
        width: 14px;
        height: 10px;
        background-image: url(../assets/img/img4.png);
        margin-right: 15px;
      }
    }
  }
  .stion {
    position: relative;
    margin-left: 20px;
    font-size: 0;
    img{
      width: 22px;
      height: 22px;
    }
    &:first-child {
      margin-left: 0;
    }
    i {
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #e63030;
    }
  }
}
</style>
